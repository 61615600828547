import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import {
  MembershipDto,
  MembershipRoleEnum,
  MembershipStatusEnum,
  MembershipRolesRequest,
  MembershipStatusRequest,
} from '../../../generated';
import { Divider, Grid, LinearProgress, Typography } from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormikForm from '../../assets/formik/FormikForm';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import useNotifications from '../../assets/useNotifications';
import { useFormik } from 'formik';
import MemberStatusChip from './MemberStatusChip';
import FormikFormControlCheckbox, { CheckboxesData } from '../../assets/formik/FormikFormControlCheckbox';
import { getMembershipsApi } from '../../../common/keycloak';
import { formatCordaX500Name } from '../../../common/format';

/**
 * JSX Element that is an overlay dialog to edit the member information
 * @param props.open if the dialog is open
 * @param props.onClose function to call when the dialog should be closed
 * @param props.onUpdate function to call when a value is updaetd
 * @param props.membership the membership to edit
 */
export default function MemberDialog(props: {
  open: boolean;
  onClose: () => void;
  onUpdate: () => void;
  membership?: MembershipDto;
}): JSX.Element {
  const { promiseInProgress } = usePromiseTracker({ area: 'member-dialog', delay: 200 });

  return (
    <Dialog open={props.open} onClose={props.onClose} aria-labelledby="form-dialog-title">
      {/* Without div the progress bar is not visible when scrollbar is shown */}
      {promiseInProgress && (
        <div>
          <LinearProgress />
        </div>
      )}
      <DialogTitle id="form-dialog-title">Edit member</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} direction="column">
          <Grid item>
            <DialogContentText style={{ maxWidth: 440 }}>
              Onboard a new member here and set its roles in the PayperChain business network.
              <br /> Currently editing: {props.membership && formatCordaX500Name(props.membership?.identity)}
            </DialogContentText>
          </Grid>
          <Grid item>
            <Divider variant="fullWidth" />
            <Typography color="textSecondary" display="block" variant="caption">
              Status
            </Typography>
          </Grid>
          <Grid item>
            <MemberDialogStatus
              open={props.open}
              membership={props.membership}
              disabled={promiseInProgress}
              onUpdate={props.onUpdate}
            />
          </Grid>
          <Grid item>
            <Divider variant="fullWidth" />
            <Typography color="textSecondary" display="block" variant="caption">
              Roles
            </Typography>
          </Grid>
          <Grid item>
            <MemberDialogRoles
              open={props.open}
              membership={props.membership}
              disabled={promiseInProgress}
              onUpdate={props.onUpdate}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={props.onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

/**
 * Element to handle and render the member status
 * @param props
 */
function MemberDialogStatus(props: {
  open: boolean;
  membership?: MembershipDto;
  disabled?: boolean;
  onUpdate: () => void;
}): JSX.Element {
  const { showError, showSuccess } = useNotifications();

  const [membershipStatus, setMembershipStatus] = useState(null as unknown as MembershipStatusEnum);

  // Initialize state
  useEffect(() => {
    if (!props.open || !props.membership) return;

    setMembershipStatus(props.membership.membershipStatus);
  }, [props.open, props.membership]);

  /**
   * Handles the status update
   */
  const handleUpdate = async function () {
    if (!membershipStatus) return;
    const request = createMembershipStatusRequest(membershipStatus);

    // Call MemberApi to send updated member
    const updateMemberStatus = async () => {
      if (!props.membership) return false;

      const api = await getMembershipsApi();
      return await api.updateMembershipStatus(props.membership.membershipId, request);
    };

    try {
      const result = await trackPromise(updateMemberStatus(), 'member-dialog');

      if (result) {
        showSuccess(`Member status updated successfully`);

        setMembershipStatus(request.membershipStatus);
        props.onUpdate();
      } else {
        showError(`Error while updating member status`);
      }
    } catch (e) {
      if (e instanceof Response) {
        showError(`Error while updating member status with status "${e.status}": ${await e.text()}`);
      } else {
        showError(JSON.stringify(e));
      }
    }
  };

  /**
   * Creates MembershipStatusRequest based on the `membershipStatus`
   * @param membershipStatus the current status of the member
   */
  const createMembershipStatusRequest = function (membershipStatus: MembershipStatusEnum): MembershipStatusRequest {
    let status: MembershipStatusEnum;

    switch (membershipStatus) {
      case MembershipStatusEnum.Active:
        status = MembershipStatusEnum.Suspended;
        break;
      case MembershipStatusEnum.Pending:
      case MembershipStatusEnum.Suspended:
        status = MembershipStatusEnum.Active;
        break;
    }

    return { membershipStatus: status };
  };

  /**
   * Element to control the label of the button based on the current `membershipStatus`
   */
  const UpdateStatusButton = function (props: { membershipStatus?: MembershipStatusEnum; disabled?: boolean }) {
    let label;

    switch (props.membershipStatus) {
      case MembershipStatusEnum.Active:
        label = 'Suspend';
        break;
      case MembershipStatusEnum.Pending:
      case MembershipStatusEnum.Suspended:
        label = 'Activate';
        break;
    }

    return (
      <Button variant="contained" color="primary" onClick={handleUpdate} disabled={props.disabled}>
        {label}
      </Button>
    );
  };

  return (
    <Grid container spacing={2}>
      <Grid container item xs={6} direction="column" justify="center">
        <Grid item style={{ textAlign: 'center' }}>
          Current status: <MemberStatusChip membershipStatus={membershipStatus} />
        </Grid>
      </Grid>
      <Grid item container xs={6} justify="center">
        <UpdateStatusButton
          membershipStatus={membershipStatus}
          disabled={props.disabled || props.membership?.membershipRoles.includes(MembershipRoleEnum.Bno)}
        />
      </Grid>
    </Grid>
  );
}

/**
 * Element to handle and render the member roles
 * @param props
 */
function MemberDialogRoles(props: {
  open: boolean;
  membership?: MembershipDto;
  disabled?: boolean;
  onUpdate: () => void;
}): JSX.Element {
  const { showError, showSuccess } = useNotifications();

  /**
   * Handles the role update
   */
  const handleUpdate = async function (roles: RolesType) {
    // Call MemberApi to send updated member
    const updateMemberRoles = async () => {
      if (!props.membership) return;

      const api = await getMembershipsApi();
      return await api.updateMembershipRoles(
        props.membership.membershipId,
        mapToMembershipDtoMembershipRolesEnumArray(roles)
      );
    };

    try {
      const result = await trackPromise(updateMemberRoles(), 'member-dialog');

      if (result) {
        showSuccess(`Member roles updated successfully`);
        props.onUpdate();
      } else {
        showError(`Error while updating member roles`);
      }
    } catch (e) {
      if (e instanceof Response) {
        showError(`Error while updating member roles with status "${e.status}": ${await e.text()}`);
      } else {
        showError(JSON.stringify(e));
      }
    }
  };

  const mapToMembershipDtoMembershipRolesEnumArray = function (membershipRoles: RolesType): MembershipRolesRequest {
    const roles = [] as MembershipRoleEnum[];

    if (membershipRoles.bno) roles.push(MembershipRoleEnum.Bno);
    if (membershipRoles.machineUser) roles.push(MembershipRoleEnum.MachineUser);
    if (membershipRoles.machineOwner) roles.push(MembershipRoleEnum.MachineOwner);
    if (membershipRoles.paymentProvider) roles.push(MembershipRoleEnum.PaymentProvider);
    if (membershipRoles.insurer) roles.push(MembershipRoleEnum.Insurer);
    if (membershipRoles.machineObserver) roles.push(MembershipRoleEnum.MachineObserver);

    return {
      membershipRoles: roles,
    };
  };

  const mapToRolesType = function (membershipRoles: MembershipRoleEnum[]): RolesType {
    const roles = {
      bno: false,
      machineUser: false,
      machineOwner: false,
      paymentProvider: false,
      machineObserver: false,
    } as RolesType;

    for (const value of membershipRoles) {
      switch (value) {
        case MembershipRoleEnum.Bno:
          roles.bno = true;
          break;
        case MembershipRoleEnum.MachineUser:
          roles.machineUser = true;
          break;
        case MembershipRoleEnum.MachineOwner:
          roles.machineOwner = true;
          break;
        case MembershipRoleEnum.PaymentProvider:
          roles.paymentProvider = true;
          break;
        case MembershipRoleEnum.Insurer:
          roles.insurer = true;
          break;
        case MembershipRoleEnum.MachineObserver:
          roles.machineObserver = true;
          break;
      }
    }

    return roles;
  };

  interface RolesType {
    bno: boolean;
    machineUser: boolean;
    machineOwner: boolean;
    paymentProvider: boolean;
    insurer: boolean;
    machineObserver: boolean;
  }

  const formik = useFormik({
    initialValues: {
      bno: false,
      machineUser: false,
      machineOwner: false,
      paymentProvider: false,
      insurer: false,
      machineObserver: false,
    } as RolesType,
    onSubmit: handleUpdate,
  });

  // Initialize
  useEffect(() => {
    if (!props.open) return;

    const initialize = async function () {
      if (!props.membership) return;

      await formik.setValues(mapToRolesType(props.membership.membershipRoles));
    };

    trackPromise(initialize(), 'member-dialog');

    // Adding 'formik' to dependencies creates an infinite loop as formik changes every render
  }, [props.open, props.membership]); // eslint-disable-line react-hooks/exhaustive-deps

  // Defining the checkboxes
  const roleCheckboxes: CheckboxesData[] = [
    {
      id: 'bno',
      label: 'Business Network Operator (BNO)',
      disabled: true,
    },
    {
      id: 'machineUser',
      label: 'Machine User',
    },
    {
      id: 'machineOwner',
      label: 'Machine Owner',
    },
    {
      id: 'paymentProvider',
      label: 'Payment Provider',
    },
    {
      id: 'insurer',
      label: 'Insurer',
    },
    {
      id: 'machineObserver',
      label: 'Machine Observer',
    },
  ];

  return (
    <FormikForm formik={formik} style={{ marginLeft: 10 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormikFormControlCheckbox formik={formik} checkboxData={roleCheckboxes} disabled={props.disabled} />
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary" type="submit" disabled={props.disabled}>
            Update Roles
          </Button>
        </Grid>
      </Grid>
    </FormikForm>
  );
}
