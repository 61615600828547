import React from 'react';
import Typography from '@material-ui/core/Typography';
import { IconButton } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';

export const DetailsBlock: React.FunctionComponent<{
  title: string;
  hasEditButton?: boolean;
  onEditButtonClick?: () => void;
  keyColumnWidth?: string;
}> = function (props) {
  return (
    <>
      <Typography gutterBottom variant="subtitle2">
        {props.title}
        {props.hasEditButton && (
          <IconButton onClick={props.onEditButtonClick}>
            <Edit fontSize="small" />
          </IconButton>
        )}
      </Typography>
      <Table padding="none">
        <colgroup>
          <col style={{ width: props.keyColumnWidth || '180px' }} />
          <col style={{}} />
        </colgroup>
        <TableBody>{props.children}</TableBody>
      </Table>
    </>
  );
};
