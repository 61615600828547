import React, { useEffect } from 'react';
import Table from '@material-ui/core/Table';
import Box from '@material-ui/core/Box';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Title } from '../../assets/Title';
import { useStyles } from '../../Styles';
import { getNetworkApi } from '../../../common/keycloak';
import GavelIcon from '@material-ui/icons/Gavel';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import { NetworkNodesDto, NodeDto, ResponseError } from '../../../generated';
import { CircularProgress } from '@material-ui/core';
import useNotifications from '../../assets/useNotifications';

/**
 * This component defines the Network component.
 */
export function NetworkPage(): JSX.Element {
  const classes = useStyles();

  const [networkInformation, setNetworkInformation] = React.useState({} as NetworkNodesDto);
  const { promiseInProgress } = usePromiseTracker({ area: 'network', delay: 200 });

  const { showError } = useNotifications();

  /** fetch data*/
  useEffect(() => {
    const loadNetworkData = async () => {
      const api = await getNetworkApi();
      try {
        const result = await api.getNetworkNodes();
        setNetworkInformation(result);
      } catch (error) {
        if (error instanceof ResponseError) {
          if (error.response && error.response.status === 401) {
            showError('User Unauthorized!');
          } else {
            showError(error.message);
          }
        } else {
          showError('There was an error fetching the data!');
        }
      }
    };
    trackPromise(loadNetworkData(), 'network');
  }, [showError]);

  const renderRow = (row: 'currentUser' | 'notary') => {
    if (Object.entries(networkInformation).length !== 0) {
      const node =
        row === 'currentUser'
          ? networkInformation.activeNode
          : networkInformation.notaries && networkInformation.notaries[0];
      return (
        <TableRow>
          <TableCell>
            <Box fontWeight="fontWeightBold" m={0}>
              {row === 'currentUser' ? 'Active Node' : 'Notary'}:
            </Box>
          </TableCell>
          <TableCell>
            {row === 'currentUser' ? <AccountCircleIcon color="primary" /> : <GavelIcon color="primary" />}
          </TableCell>
          <TableCell>{node?.identity?.organization}</TableCell>
          <TableCell>{node?.identity?.locality}</TableCell>
          <TableCell>{node?.identity?.country}</TableCell>
        </TableRow>
      );
    }
  };

  const renderPeers = () => {
    if (Object.entries(networkInformation).length !== 0) {
      return networkInformation.otherNodes?.map((element: NodeDto, index: number) => (
        <TableRow key={'rowindex' + index}>
          <TableCell>
            {index === 0 ? (
              <Box fontWeight="fontWeightBold" m={0}>
                Other Nodes:
              </Box>
            ) : (
              ''
            )}
          </TableCell>
          <TableCell>{index === 0 ? <AccountTreeIcon color="primary" /> : ''}</TableCell>
          <TableCell>{element?.identity?.organization}</TableCell>
          <TableCell>{element?.identity?.locality}</TableCell>
          <TableCell>{element?.identity?.country}</TableCell>
        </TableRow>
      ));
    }
  };

  return (
    <React.Fragment>
      <Title>Network Information</Title>
      <br />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell />
            <TableCell>Organization</TableCell>
            <TableCell>Locality</TableCell>
            <TableCell>Country</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {renderRow('currentUser')}
          {renderPeers()}
          {renderRow('notary')}
        </TableBody>
      </Table>
      {promiseInProgress && (
        <div className={classes.spinner}>
          <CircularProgress />
        </div>
      )}
      <div className={classes.seeMore}>
        <Typography component="h1" color="inherit" noWrap>
          Corda Version: 4.8
        </Typography>
      </div>
    </React.Fragment>
  );
}
