import React, { useEffect, useState } from 'react';
import { MachineDto } from '../../../generated';
import { CircularProgress, IconButton, List, ListItem, ListItemText, Paper } from '@material-ui/core';
import { MaterialTableIcons } from '../../assets/MaterialTableIcons';
import { MachineDetails } from './MachineDetails';
import MaterialTable, { Column } from 'material-table';
import { formatCordaX500Name } from '../../../common/format';
import { NumberFormatEurText } from '../../assets/numberformat/NumberFormatEur';
import { Edit } from '@material-ui/icons';
import { Config } from '../../../common/config';
import { getNetworkApi } from '../../../common/keycloak';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';

export function MachinesTable(props: {
  machinesData: MachineDto[];
  promiseInProgress: boolean;
  setEditMachineDialog: (open: { open: boolean; machine: MachineDto }) => void;
}): JSX.Element {
  const { promiseInProgress } = usePromiseTracker({ area: 'my-identity', delay: 200 });
  const [myIdentity, setMyIdentity] = useState('');

  // Get my identity to show edit buttons only when machineOwner of machine is equal to it
  useEffect(() => {
    if (!Config.machinesPage.canEditMachines) return;

    const getIdentity = async function () {
      const networkApi = await getNetworkApi();
      const currentNode = await networkApi.getActiveNode();

      setMyIdentity(formatCordaX500Name(currentNode.identity));
    };

    trackPromise(getIdentity(), 'my-identity');
  }, []);

  console.log('myIdentity:', myIdentity);
  console.log('props.machinesData in MACHINE TABLE:', props.machinesData);

  /**
   * Define the columns including format/type and filtering
   */
  const ColumnMachineOwner: Column<MachineDto> = {
    title: 'Machine Owner',
    field: 'machineOwner',
    render: (rowData: MachineDto) => formatCordaX500Name(rowData.machineOwner),
    filterPlaceholder: 'contains',
  };

  const ColumnMachineUser: Column<MachineDto> = {
    title: 'Machine User',
    field: 'machineUser',
    render: (rowData: MachineDto) => formatCordaX500Name(rowData.machineUser),
    filterPlaceholder: 'contains',
  };

  const ColumnMachineName: Column<MachineDto> = {
    title: 'Machine Name',
    field: 'machineName',
    filterPlaceholder: 'contains',
    defaultSort: 'asc',
  };

  const ColumnPricePerUnit: Column<MachineDto> = {
    title: 'Price per Unit',
    field: 'pricePerUnit',
    headerStyle: { textAlign: 'right' },
    cellStyle: { textAlign: 'right' },
    render: (rowData: MachineDto) => <NumberFormatEurText value={rowData.pricePerUnit} />,
    customFilterAndSearch: (term: number, rowData: MachineDto) => term <= rowData.pricePerUnit,
    filterPlaceholder: 'larger than',
  };

  const ColumnPaymentThreshold: Column<MachineDto> = {
    title: 'Payment Threshold',
    field: 'paymentThreshold',
    headerStyle: { textAlign: 'right' },
    cellStyle: { textAlign: 'right' },
    render: (rowData: MachineDto) => <NumberFormatEurText value={rowData.paymentThreshold} />,
    customFilterAndSearch: (term: number, rowData: MachineDto) => term <= (rowData.paymentThreshold ?? 0),
    filterPlaceholder: 'larger than',
  };

  const ColumnPaymentProvider: Column<MachineDto> = {
    title: 'Payment Provider',
    field: 'paymentProvider',
    render: (rowData: MachineDto) => formatCordaX500Name(rowData.paymentProvider),
    filterPlaceholder: 'contains',
  };

  const ColumnInsurers: Column<MachineDto> = {
    title: 'Insurers',
    field: 'insurers',
    render: (rowData: MachineDto) => (
      <List dense disablePadding>
        {rowData.insurers.map((insurer, index) => {
          return (
            <ListItem dense disableGutters key={index + 10000}>
              <ListItemText>{formatCordaX500Name(insurer)}</ListItemText>
            </ListItem>
          );
        })}
      </List>
    ),
    filterPlaceholder: 'contains',
  };

  // Show Edit button only when myIdentity equals machineOwner of the machine
  const ColumnEditMachine: Column<MachineDto> = {
    title: 'Edit',
    sorting: false,
    headerStyle: { textAlign: 'center' },
    cellStyle: { textAlign: 'center' },
    render: function (rowData: MachineDto) {
      if (myIdentity != formatCordaX500Name(rowData.machineOwner)) return;

      return (
        <>
          <IconButton
            onClick={() => {
              props.setEditMachineDialog({ open: true, machine: rowData });
            }}
          >
            <Edit />
          </IconButton>
        </>
      );
    },
  };

  const columnDefs: Column<MachineDto>[] = [
    ColumnMachineName,
    ColumnPricePerUnit,
    ColumnPaymentThreshold,
    ColumnMachineOwner,
    ColumnMachineUser,
    ColumnPaymentProvider,
    ColumnInsurers,
  ];

  // Add edit button
  if (Config.machinesPage.canEditMachines) {
    columnDefs.push(ColumnEditMachine);
  }

  return (
    <MaterialTable
      localization={{
        body: {
          emptyDataSourceMessage:
            props.promiseInProgress || promiseInProgress ? <CircularProgress /> : 'No records to display',
        },
      }}
      components={{
        Container: (props) => <Paper {...props} elevation={0} />,
      }}
      icons={MaterialTableIcons()}
      columns={columnDefs}
      data={promiseInProgress ? [] : props.machinesData}
      detailPanel={(row) => {
        return <MachineDetails machine={row} />;
      }}
      options={{
        filtering: true,
        sorting: true,
        showTitle: false,
      }}
    />
  );
}
