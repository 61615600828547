import { InsuranceStatusEnum } from '../../../generated';
import ColorChip from '../../assets/ColorChip';
import React from 'react';
import { ChipProps } from '@material-ui/core';

type InsuranceStatusChipProps = ChipProps & { insuranceStatus?: InsuranceStatusEnum };

/**
 * Show the insurance status as chip
 * @param props the status
 */
export default function InsuranceStatusChip(props: InsuranceStatusChipProps): JSX.Element {
  const { insuranceStatus, ...other } = props;

  let chipColor: 'grey' | 'green' | 'orange' = 'green';
  let label = '';
  switch (insuranceStatus) {
    case InsuranceStatusEnum.Active: {
      chipColor = 'green';
      label = 'Active';
      break;
    }
    case InsuranceStatusEnum.Inactive: {
      chipColor = 'orange';
      label = 'Inactive';
      break;
    }
    case InsuranceStatusEnum.Canceled: {
      chipColor = 'grey';
      label = 'Canceled';
      break;
    }
  }

  return <ColorChip chipColor={chipColor} label={label} {...other} />;
}
