import React from 'react';
import { Dashboard } from './components/dashboard/Dashboard';
import keycloak from './common/keycloak';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { KeycloakInitOptions } from 'keycloak-js';
import { SnackbarProvider } from 'notistack';
import { BrowserRouter } from 'react-router-dom';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

// Log Token and Keycloak events for debugging
const eventLogger = (event: unknown, error: unknown) => {
  console.log('onKeycloakEvent', event, error);
};
const tokenLogger = (tokens: unknown) => {
  console.log('onKeycloakTokens', tokens);
};

function App(): JSX.Element {
  // Redirect to keycloak login page if not logged in
  const keycloakProviderInitConfig: KeycloakInitOptions = {
    onLoad: 'login-required',
  };

  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      onEvent={eventLogger}
      onTokens={tokenLogger}
      initOptions={keycloakProviderInitConfig}
    >
      <SnackbarProvider
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <BrowserRouter>
            <Dashboard />
          </BrowserRouter>
        </MuiPickersUtilsProvider>
      </SnackbarProvider>
    </ReactKeycloakProvider>
  );
}

export default App;
