import React from 'react';
import {
  InsuranceDetailsDto,
  InsuranceDto,
  InsuranceMachineDetailsDto,
  InsurancePaymentDetailsDto,
  InsurancePayPerUseDetailsDto,
} from '../../../generated';
import { Grid, List, ListItem, ListItemIcon, ListItemText, Paper } from '@material-ui/core';
import { useStyles } from '../../Styles';
import { formatDateTime } from '../../../common/format';
import { NumberFormatEurText } from '../../assets/numberformat/NumberFormatEur';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { InsuranceDialogState } from './InsuranceDialog';
import { DetailsBlock } from '../../assets/details/DetailsBlock';
import { DetailsEntry } from '../../assets/details/DetailsEntry';
import { Config } from '../../../common/config';
import { InsuranceInvoices } from './invoices/InsuranceInvoices';
import { NumberFormatUnitsText } from '../../assets/numberformat/NumberFormatUnits';

export function InsuranceDetails(props: {
  insurance: InsuranceDto;
  setEditInsuranceDialog: (open: { open: InsuranceDialogState; insurance: InsuranceDto }) => void;
}): JSX.Element {
  const classes = useStyles();

  return (
    <div className={classes.divTable}>
      <Grid item container direction="column" spacing={1}>
        <Grid item>
          <Paper className={classes.papertable}>
            <Grid container direction="row" spacing={4}>
              <Grid item md={6} xs={12}>
                <InsuranceDetailsBlock
                  insuranceDetails={props.insurance.insuranceDetails}
                  onEditButtonClick={() =>
                    props.setEditInsuranceDialog({
                      open: InsuranceDialogState.InsuranceDetailsDialog,
                      insurance: props.insurance,
                    })
                  }
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <MachineDetailsBlock
                  machineDetails={props.insurance.machineDetails}
                  machineId={props.insurance.machineId}
                  onEditButtonClick={() =>
                    props.setEditInsuranceDialog({
                      open: InsuranceDialogState.MachineDetailsDialog,
                      insurance: props.insurance,
                    })
                  }
                />
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={4}>
              <Grid item md={6} xs={12}>
                <PaymentDetailsBlock
                  paymentDetails={props.insurance.paymentDetails}
                  onEditButtonClick={() =>
                    props.setEditInsuranceDialog({
                      open: InsuranceDialogState.PaymentDetailsDialog,
                      insurance: props.insurance,
                    })
                  }
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <PayPerUseDetailsBlock
                  payperUseDetails={props.insurance.payPerUseDetails}
                  onEditButtonClick={() =>
                    props.setEditInsuranceDialog({
                      open: InsuranceDialogState.PayPerUseDetailsDialog,
                      insurance: props.insurance,
                    })
                  }
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item>
          <Paper className={classes.papertable}>
            <InsuranceInvoices invoiceExternalId={props.insurance.insuranceExternalId} />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

const InsuranceDetailsBlock = function (props: {
  insuranceDetails: InsuranceDetailsDto;
  onEditButtonClick: () => void;
}) {
  return (
    <DetailsBlock
      title="Insurance Details"
      hasEditButton={Config.insurancePage.canEditInsurances}
      onEditButtonClick={props.onEditButtonClick}
    >
      <DetailsEntry property="Type">{props.insuranceDetails.type}</DetailsEntry>
      <DetailsEntry property="Start timestamp">{formatDateTime(props.insuranceDetails.startTimestamp)}</DetailsEntry>
      <DetailsEntry property="End timestamp">{formatDateTime(props.insuranceDetails.endTimestamp)}</DetailsEntry>
      <DetailsEntry property="Insured amount">
        <NumberFormatEurText value={props.insuranceDetails.insuredAmount} />
      </DetailsEntry>
      <DetailsEntry property="Excess amount">
        <NumberFormatEurText value={props.insuranceDetails.excessAmount} />
      </DetailsEntry>
      <DetailsEntry property="Insured risks">
        <List dense disablePadding>
          {props.insuranceDetails.insuredRisks?.map((risk, index) => {
            return (
              <ListItem disableGutters style={{ paddingTop: 0, paddingBottom: 0 }} key={index + 10000}>
                <ListItemIcon style={{ minWidth: 0, paddingRight: '5px' }}>
                  <CheckCircleIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>{risk}</ListItemText>
              </ListItem>
            );
          })}
        </List>
      </DetailsEntry>
    </DetailsBlock>
  );
};

const MachineDetailsBlock = function (props: {
  machineDetails: InsuranceMachineDetailsDto;
  machineId: string;
  onEditButtonClick: () => void;
}) {
  return (
    <DetailsBlock
      title="Machine Details"
      hasEditButton={Config.insurancePage.canEditInsurances}
      onEditButtonClick={props.onEditButtonClick}
    >
      <DetailsEntry property="Machine ID">{props.machineId}</DetailsEntry>
      <DetailsEntry property="Type">{props.machineDetails.type}</DetailsEntry>
      <DetailsEntry property="Manufacturer">{props.machineDetails.manufacturer}</DetailsEntry>
      <DetailsEntry property="Serial number">{props.machineDetails.serialNumber}</DetailsEntry>
      <DetailsEntry property="Manufacturing date">{props.machineDetails.manufacturingDate}</DetailsEntry>
      <DetailsEntry property="Location">{props.machineDetails.location}</DetailsEntry>
    </DetailsBlock>
  );
};

const PaymentDetailsBlock = function (props: {
  paymentDetails: InsurancePaymentDetailsDto;
  onEditButtonClick: () => void;
}) {
  return (
    <DetailsBlock
      title="Payment Details"
      hasEditButton={Config.insurancePage.canEditInsurances}
      onEditButtonClick={props.onEditButtonClick}
    >
      <DetailsEntry property="Method">{props.paymentDetails.method}</DetailsEntry>
      <DetailsEntry property="Type">{props.paymentDetails.type}</DetailsEntry>
      <DetailsEntry property="Basis">{props.paymentDetails.basis}</DetailsEntry>
      <DetailsEntry property="Interval">
        <span style={{ textTransform: 'capitalize' }}>{props.paymentDetails.interval?.toLowerCase()}</span>
      </DetailsEntry>
    </DetailsBlock>
  );
};

const PayPerUseDetailsBlock = function (props: {
  payperUseDetails: InsurancePayPerUseDetailsDto;
  onEditButtonClick: () => void;
}) {
  return (
    <DetailsBlock
      title="Pay per Use Details"
      hasEditButton={Config.insurancePage.canEditInsurances}
      onEditButtonClick={props.onEditButtonClick}
    >
      <DetailsEntry property="Annual expected amount">
        <NumberFormatEurText value={props.payperUseDetails.annualExpectedAmount} />
      </DetailsEntry>
      <DetailsEntry property="Annual base amount">
        <NumberFormatEurText value={props.payperUseDetails.annualBaseAmount} />
      </DetailsEntry>
      <DetailsEntry property="Annual cap amount">
        <NumberFormatEurText value={props.payperUseDetails.annualCapAmount} />
      </DetailsEntry>
      <DetailsEntry property="Annual expected activity">
        <NumberFormatUnitsText value={props.payperUseDetails.annualExpectedActivity} />
      </DetailsEntry>
      <DetailsEntry property="Price per unit">
        <NumberFormatEurText value={props.payperUseDetails.pricePerUnit} />
      </DetailsEntry>
    </DetailsBlock>
  );
};
