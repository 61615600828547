import React from 'react';
import { TextField } from '@material-ui/core';
import { TextFieldProps } from '@material-ui/core/TextField/TextField';
import get from 'lodash/get';
import { UseFormikType } from './useFormikType';

// Extend <TextField> property type with "formik"
type FormikTextFieldProps = TextFieldProps & { formik: UseFormikType };

/**
 * Wrapper of <TextField> to set value, onChange, error, helperText based on values managed by formik.
 * @param props default <TextField> properties extended with "formik" parameter
 */
export default function FormikTextField(props: FormikTextFieldProps): JSX.Element {
  const { formik, id, ...other } = props;

  if (!id) throw new Error('FormikTextField "id" property must not be empty');
  // Function to handle input click
  const handleClick = () => {
    // Clear input field when clicked if it's 0
    const fieldValue = get(formik.values, id, '');

    if (fieldValue === 0) {
      formik.setFieldValue(id, '');
      // Trigger field's onBlur event to update Formik's touched state
      formik.handleBlur(id);
    }
  };
  return (
    <TextField
      id={id}
      value={get(formik.values, id, '')}
      onChange={formik.handleChange}
      onClick={handleClick}
      onBlur={formik.handleBlur}
      error={get(formik.touched, id, false) && Boolean(get(formik.errors, id))}
      helperText={get(formik.touched, id, false) && get(formik.errors, id)}
      {...other}
    />
  );
}
