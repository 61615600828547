import React from 'react';
import { CircularProgress, IconButton, Paper } from '@material-ui/core';
import { MaterialTableIcons } from '../../assets/MaterialTableIcons';
import MaterialTable, { Column } from 'material-table';
import { InsuranceDto } from '../../../generated';
import { dateComparer, formatCordaX500Name, formatDateTime } from '../../../common/format';
import { InsuranceDetails } from './InsuranceDetails';
import InsuranceStatusChip from './InsuranceStatusChip';
import { Edit } from '@material-ui/icons';
import { Config } from '../../../common/config';
import { InsuranceDialogState } from './InsuranceDialog';

export function InsurancesTable(props: {
  insurancesData: InsuranceDto[];
  promiseInProgress: boolean;
  setEditInsuranceDialog: (open: { open: InsuranceDialogState; insurance: InsuranceDto }) => void;
}): JSX.Element {
  const ColumnExternalId: Column<InsuranceDto> = {
    title: 'Insurance Number',
    field: 'insuranceExternalId',
    filterPlaceholder: 'contains',
    defaultSort: 'asc',
  };

  const ColumnMachineName: Column<InsuranceDto> = {
    title: 'Machine Name',
    field: 'machineName',
    filterPlaceholder: 'contains',
  };

  const ColumnInsurer: Column<InsuranceDto> = {
    title: 'Insurer',
    field: 'insurer',
    render: (data: InsuranceDto) => formatCordaX500Name(data.insurer),
    filterPlaceholder: 'contains',
  };

  const ColumnCounterparty: Column<InsuranceDto> = {
    title: 'Counterparty',
    field: 'counterparty',
    render: (data: InsuranceDto) => formatCordaX500Name(data.counterparty),
    filterPlaceholder: 'contains',
  };

  const ColumnStatus: Column<InsuranceDto> = {
    title: 'Status',
    field: 'insuranceStatus',
    filterPlaceholder: 'contains',
    headerStyle: { textAlign: 'center' },
    cellStyle: { textAlign: 'center' },
    render: (data: InsuranceDto) => <InsuranceStatusChip insuranceStatus={data.insuranceStatus} size="small" />,
  };

  const ColumnCreated: Column<InsuranceDto> = {
    title: 'Created',
    field: 'created',
    type: 'date',
    render: (data: InsuranceDto) => formatDateTime(data.created),
    customFilterAndSearch: (term: string, data: InsuranceDto) => dateComparer(term, data.created),
    filterPlaceholder: 'select date',
  };

  const ColumnUpdated: Column<InsuranceDto> = {
    title: 'Updated',
    field: 'updated',
    type: 'date',
    render: (data: InsuranceDto) => formatDateTime(data.updated),
    customFilterAndSearch: (term: string, data: InsuranceDto) => dateComparer(term, data.updated),
    filterPlaceholder: 'select date',
  };

  const ColumnEdit: Column<InsuranceDto> = {
    title: 'Edit',
    sorting: false,
    headerStyle: { textAlign: 'center' },
    cellStyle: { textAlign: 'center' },
    render: (data: InsuranceDto) => (
      <IconButton
        onClick={() => {
          props.setEditInsuranceDialog({ open: InsuranceDialogState.InsuranceBasicDialog, insurance: data });
        }}
      >
        <Edit />
      </IconButton>
    ),
  };

  const columnDefs: Column<InsuranceDto>[] = [
    ColumnExternalId,
    ColumnMachineName,
    ColumnInsurer,
    ColumnCounterparty,
    ColumnStatus,
    ColumnCreated,
    ColumnUpdated,
  ];

  // Add edit button
  if (Config.insurancePage.canEditInsurances) {
    columnDefs.push(ColumnEdit);
  }

  return (
    <MaterialTable
      localization={{
        body: {
          emptyDataSourceMessage: props.promiseInProgress ? <CircularProgress /> : 'No records to display',
        },
      }}
      components={{
        Container: (props) => <Paper {...props} elevation={0} />,
      }}
      icons={MaterialTableIcons()}
      columns={columnDefs}
      data={props.insurancesData}
      detailPanel={(row) => {
        return <InsuranceDetails insurance={row} setEditInsuranceDialog={props.setEditInsuranceDialog} />;
      }}
      options={{
        filtering: true,
        sorting: true,
        showTitle: false,
      }}
    />
  );
}
