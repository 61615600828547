import React from 'react';
import Link from '@material-ui/core/Link';
import { Title } from '../../assets/Title';
import Typography from '@material-ui/core/Typography';

/**
 * Definition of the about view component.
 */
export function AboutPage(): JSX.Element {
  return (
    <React.Fragment>
      <Title>About Us</Title>
      <br />
      <Typography variant="body1">We make Pay Per Use secure.</Typography>
      <br />
      <Typography variant="body2">
        Using the latest blockchain technology, we provide highly flexible payment terms and fully automated payment
        flows.
      </Typography>
      <Typography variant="body2">
        Invest - regardless of your current order situation. Bring more flexibility to your cash flow. Increase your
        long term revenues.
      </Typography>
      <p>
        <Link href="mailto:info@payperchain.com">Contact us!</Link>
      </p>
      <Typography variant="body2">
        <Link href="https://www.payperchain.com/">PayperChain GmbH</Link> | Vor dem Langen Loh 6 | 35075
        Gladenbach-Mornshausen
      </Typography>
    </React.Fragment>
  );
}
