import {
  routeAbout,
  routeInsurances,
  routeMachines,
  routeMembers,
  routeNetwork,
  routePayments,
  RouteType,
  routeWallet,
} from '../components/pages/routes';
import ExternalConfig from './ExternalConfig';

/**
 * Default config that is overwritten below based on defined user roles
 */
const ConfigDefault = {
  routes: [] as RouteType[],
  homeRoute: null as unknown as RouteType,
  dashboard: {
    profile: ExternalConfig.REACT_APP_PROFILE,
    title: ExternalConfig.REACT_APP_TITLE,
    allowManagePaymentInformation: false,
  },
  walletPage: {
    showOverviewBox: false,
  },
  machinesPage: {
    canEditMachines: false,
  },
  insurancePage: {
    canEditInsurances: false,
  },
  membersPage: {
    canEditMembers: false,
    canCreateBusinessNetwork: false,
  },
};

const UserConfig = ConfigDefault;
switch (ExternalConfig.REACT_APP_PROFILE) {
  case 'MACHINE_USER': {
    // Routes
    UserConfig.routes = [
      routeWallet,
      routePayments,
      routeMachines,
      routeInsurances,
      routeMembers,
      routeNetwork,
      routeAbout,
    ];
    UserConfig.homeRoute = routeWallet;

    // Dashboard
    UserConfig.dashboard.allowManagePaymentInformation = true;

    // WalletPage
    UserConfig.walletPage.showOverviewBox = true;
    break;
  }

  case 'MACHINE_OWNER': {
    // Routes
    UserConfig.routes = [
      routeWallet,
      routePayments,
      routeMachines,
      routeInsurances,
      routeMembers,
      routeNetwork,
      routeAbout,
    ];
    UserConfig.homeRoute = routeWallet;

    // Dashboard
    UserConfig.dashboard.allowManagePaymentInformation = true;

    // WalletPage
    UserConfig.walletPage.showOverviewBox = true;

    // MachinePage
    UserConfig.machinesPage.canEditMachines = true;
    break;
  }

  case 'PAYPERCHAIN': {
    // Routes
    UserConfig.routes = [
      routeWallet,
      routePayments,
      routeMachines,
      routeInsurances,
      routeMembers,
      routeNetwork,
      routeAbout,
    ];
    UserConfig.homeRoute = routeWallet;

    // Dashboard
    UserConfig.dashboard.allowManagePaymentInformation = true;

    // WalletPage
    UserConfig.walletPage.showOverviewBox = true;

    // MachinesPage
    UserConfig.machinesPage.canEditMachines = true;

    // MembershipsPage
    UserConfig.membersPage.canEditMembers = true;
    UserConfig.membersPage.canCreateBusinessNetwork = true;
    break;
  }

  case 'INSURER': {
    // Routes
    UserConfig.routes = [routeInsurances, routeMachines, routeMembers, routeNetwork, routeAbout];
    UserConfig.homeRoute = routeInsurances;

    // InsurancePage
    UserConfig.insurancePage.canEditInsurances = true;

    break;
  }

  case 'MACHINE_OBSERVER': {
    // Routes
    UserConfig.routes = [routeMachines, routeMembers, routeNetwork, routeAbout];
    UserConfig.homeRoute = routeMachines;
    break;
  }

  case 'PAYMENT_PROVIDER': {
    // Routes
    UserConfig.routes = [routeWallet, routePayments, routeMachines, routeMembers, routeNetwork, routeAbout];
    UserConfig.homeRoute = routeWallet;
    break;
  }
  default: {
    throw new Error(
      'REACT_APP_PROFILE has to be a valid value out of: PAYMENT_PROVIDER; MACHINE_OBSERVER; MACHINE_OWNER; MACHINE_USER; PAYPERCHAIN; INSURER'
    );
  }
}

// Export
export const Config = UserConfig;
