import NumberFormat, { InputAttributes, NumberFormatProps } from 'react-number-format';
import React from 'react';

interface NumberFormatEurProps {
  inputRef: (instance: NumberFormat<InputAttributes> | null) => void;
  onChange: (event: { target: { name: string; value: string } }) => void;
  id: string;
}

/**
 * NumberFormat input field that formats the input as € currency.
 * It can be used with `FormatTextField` by adding following parameter:
 * ```
 * InputProps={{
 *     inputComponent: NumberFormatEurInput as never,
 * }}
 * ```
 * @param props
 */
export function NumberFormatEurInput(props: NumberFormatEurProps): JSX.Element {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormatEur
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.id,
            value: values.value,
          },
        });
      }}
    />
  );
}

/**
 * Number format text field that formats the value.
 * Usage:
 * ```
 * <NumberFormatEurText value={123321.4444} />
 * ```
 * results in `€ 123,321.44`
 * @param props
 * @constructor
 */
export function NumberFormatEurText(props: NumberFormatProps): JSX.Element {
  return <NumberFormatEur {...props} displayType="text" />;
}

/**
 * Base function to set consistent currency formatting
 * @param props
 * @constructor
 */
function NumberFormatEur(props: NumberFormatProps): JSX.Element {
  return (
    <NumberFormat
      {...props}
      decimalScale={2}
      fixedDecimalScale
      thousandSeparator="."
      decimalSeparator=","
      isNumericString
      prefix=""
      suffix=" €"
    />
  );
}
