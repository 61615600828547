import React from 'react';

import { PaymentDto, PaymentStatusEnum } from '../../../generated';
import { CircularProgress, Paper } from '@material-ui/core';
import { MaterialTableIcons } from '../../assets/MaterialTableIcons';
import { PaymentDetails } from './PaymentDetails';
import MaterialTable, { Column } from 'material-table';
import { dateComparer, formatCordaX500Name, formatDateTime } from '../../../common/format';
import { NumberFormatEurText } from '../../assets/numberformat/NumberFormatEur';
import ColorChip from '../../assets/ColorChip';
import { NumberFormatUnitsText } from '../../assets/numberformat/NumberFormatUnits';

export function PaymentsTable(props: { paymentsData: PaymentDto[]; promiseInProgress: boolean }): JSX.Element {
  /**
   * Define the columns including format/type and filtering
   */
  const ColumnPaymentTimestamp: Column<PaymentDto> = {
    title: 'Timestamp',
    field: 'created',
    type: 'date',
    render: (data: PaymentDto) => formatDateTime(data.created),
    customFilterAndSearch: (term: string, data: PaymentDto) => dateComparer(term, data.created),
    filterPlaceholder: 'select date',
    defaultSort: 'desc',
  };

  const ColumnMachineOwner: Column<PaymentDto> = {
    title: 'Machine Owner',
    field: 'machineOwner',
    render: (data: PaymentDto) => formatCordaX500Name(data.machineOwner),
    filterPlaceholder: 'contains',
  };

  const ColumnMachineUser: Column<PaymentDto> = {
    title: 'Machine User',
    field: 'machineUser',
    render: (data: PaymentDto) => formatCordaX500Name(data.machineUser),
    filterPlaceholder: 'contains',
  };

  const ColumnMachineName: Column<PaymentDto> = {
    title: 'Machine Name',
    field: 'machineName',
    filterPlaceholder: 'contains',
  };

  const ColumnTotalUnits: Column<PaymentDto> = {
    title: 'Units',
    field: 'totalUnits',
    headerStyle: { textAlign: 'right' },
    cellStyle: { textAlign: 'right' },
    render: (data: PaymentDto) => <NumberFormatUnitsText value={data.totalUnits} />,
    customFilterAndSearch: (term: number, rowData: PaymentDto) => term <= rowData.totalUnits,
    filterPlaceholder: 'larger than',
  };

  const ColumnTotalAmount: Column<PaymentDto> = {
    title: 'Amount',
    field: 'totalAmount',
    headerStyle: { textAlign: 'right' },
    cellStyle: { textAlign: 'right' },
    render: (data: PaymentDto) => <NumberFormatEurText value={data.totalAmount} />,
    customFilterAndSearch: (term: number, rowData: PaymentDto) => term <= rowData.totalAmount,
    filterPlaceholder: 'larger than',
  };

  function PaymentStatusChip(props: { paymentStatus: PaymentStatusEnum }) {
    let chipColor: 'green' | 'orange' | 'grey' = 'grey';
    switch (props.paymentStatus) {
      case PaymentStatusEnum.Initiated: {
        chipColor = 'grey';
        break;
      }
      case PaymentStatusEnum.Pending: {
        chipColor = 'orange';
        break;
      }
      case PaymentStatusEnum.Confirmed: {
        chipColor = 'green';
        break;
      }
    }

    return <ColorChip chipColor={chipColor} size="small" label={props.paymentStatus} />;
  }

  const ColumnPaymentStatus: Column<PaymentDto> = {
    title: 'Status',
    field: 'paymentStatus',
    headerStyle: { textAlign: 'center' },
    cellStyle: {
      textAlign: 'center',
    },
    render: (data: PaymentDto) => <PaymentStatusChip paymentStatus={data.paymentStatus} />,
    filterPlaceholder: 'contains',
  };

  const ColumnPaymentLastUpdate: Column<PaymentDto> = {
    title: 'Last Update',
    field: 'updated',
    type: 'date',
    render: (data: PaymentDto) => formatDateTime(data.updated),
    customFilterAndSearch: (term: string, data: PaymentDto) => dateComparer(term, data.updated),
    filterPlaceholder: 'select date',
  };

  const columnDefs: Column<PaymentDto>[] = [
    ColumnPaymentTimestamp,
    ColumnMachineOwner,
    ColumnMachineUser,
    ColumnMachineName,
    ColumnTotalUnits,
    ColumnTotalAmount,
    ColumnPaymentStatus,
    ColumnPaymentLastUpdate,
  ];

  return (
    <MaterialTable
      localization={{
        body: {
          emptyDataSourceMessage: props.promiseInProgress ? <CircularProgress /> : 'No records to display',
        },
      }}
      components={{
        Container: (props) => <Paper {...props} elevation={0} />,
      }}
      icons={MaterialTableIcons()}
      columns={columnDefs}
      data={props.paymentsData}
      detailPanel={(row) => {
        return <PaymentDetails payment={row} />;
      }}
      options={{
        filtering: true,
        sorting: true,
        showTitle: false,
      }}
    />
  );
}
