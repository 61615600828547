import { AccountBalanceWallet, Autorenew, Build, DeviceHub, Group, Info, VerifiedUser } from '@material-ui/icons';
import { AboutPage } from './about/AboutPage';
import { NetworkPage } from './network/NetworkPage';
import { WalletPage } from './wallet/WalletPage';
import { MachinesPage } from './machines/MachinesPage';
import { PaymentsPage } from './payments/PaymentsPage';
import { MembersPage } from './members/MembersPage';
import { InsurancesPage } from './insurance/InsurancesPage';

type IconType = typeof DeviceHub;

/**
 * Type definition of the routes.
 */
export interface RouteType {
  path: string;
  label: string;
  exact?: boolean;
  icon: IconType;
  component: () => JSX.Element;
}

/**
 * This are the routes that are available in
 * the application. It maps the path name onto a react
 * component. It also defines the styling of the route in
 * the navigation bar.
 */

export const routeWallet: RouteType = {
  path: '/wallet',
  label: 'Wallet',
  icon: AccountBalanceWallet,
  component: WalletPage,
};

export const routePayments: RouteType = {
  path: '/payments',
  label: 'Payments',
  icon: Autorenew,
  component: PaymentsPage,
};

export const routeMachines: RouteType = {
  path: '/machines',
  label: 'Machines',
  icon: Build,
  component: MachinesPage,
};

export const routeInsurances: RouteType = {
  path: '/insurances',
  label: 'Insurances',
  icon: VerifiedUser,
  component: InsurancesPage,
};

export const routeMembers: RouteType = {
  path: '/members',
  label: 'Members',
  icon: Group,
  component: MembersPage,
};

export const routeNetwork: RouteType = {
  path: '/network',
  label: 'Network',
  icon: DeviceHub,
  component: NetworkPage,
};

export const routeAbout: RouteType = {
  path: '/about',
  label: 'About',
  icon: Info,
  component: AboutPage,
};
