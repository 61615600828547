import React from 'react';
import { InsuranceInvoiceDto } from '../../../../generated';

import { Grid, Paper } from '@material-ui/core';
import { useStyles } from '../../../Styles';

import { DetailsBlock } from '../../../assets/details/DetailsBlock';
import { DetailsEntry } from '../../../assets/details/DetailsEntry';
import { NumberFormatUnitsText } from '../../../assets/numberformat/NumberFormatUnits';
import { formatDate, formatDateTime } from '../../../../common/format';

export function InsuranceInvoiceDetails(props: { insuranceInvoice: InsuranceInvoiceDto }): JSX.Element {
  const classes = useStyles();

  return (
    <div className={classes.divTable}>
      <Grid item container direction="column" spacing={1}>
        <Grid item>
          <Paper className={classes.papertable}>
            <Grid container direction="row" spacing={4}>
              <Grid item md={6} xs={12}>
                <DetailsBlock title="Invoice Details">
                  <DetailsEntry property="Invoice date">
                    {formatDate(props.insuranceInvoice.invoiceDetails.invoiceDate)}
                  </DetailsEntry>
                  <DetailsEntry property="Payment date">
                    {formatDate(props.insuranceInvoice.invoiceDetails.paymentDate)}
                  </DetailsEntry>
                  <DetailsEntry property="Billed activity units">
                    <NumberFormatUnitsText value={props.insuranceInvoice.invoiceDetails.billedActivityUnits} />
                  </DetailsEntry>
                  <DetailsEntry property="Recorded activity units">
                    <NumberFormatUnitsText value={props.insuranceInvoice.invoiceDetails.recordedActivityUnits} />
                  </DetailsEntry>
                </DetailsBlock>
              </Grid>
              <Grid item md={6} xs={12}>
                <DetailsBlock title="Timestamps">
                  <DetailsEntry property="Created">{formatDateTime(props.insuranceInvoice.created)}</DetailsEntry>
                  <DetailsEntry property="Updated">{formatDateTime(props.insuranceInvoice.updated)}</DetailsEntry>
                </DetailsBlock>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
