import React, { useEffect, useState } from 'react';
import { getMachinesApi, getMemberApi } from '../../../common/keycloak';
import { Grid } from '@material-ui/core';
import { Title } from '../../assets/Title';
import Button from '@material-ui/core/Button';
import { Cached } from '@material-ui/icons';
import { useStyles } from '../../Styles';
import { MachineDto, MemberDto, ResponseError } from '../../../generated';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import { Config } from '../../../common/config';
import useNotifications from '../../assets/useNotifications';
import AddIcon from '@material-ui/icons/Add';
import MachineDialog from './MachineDialog';
import { MachinesTable } from './MachinesTable';

/**
 * Definition of the component that displays price and invoice information for
 * every machine and lets the user change these parameters (depending on the
 * user role).
 */
export function MachinesPage(): JSX.Element {
  const classes = useStyles();
  const { promiseInProgress } = usePromiseTracker({ area: 'machines', delay: 200 });
  const { showError } = useNotifications();

  const [machinesData, setMachinesData] = useState(new Array<MachineDto>());

  interface EditMachineDialogData {
    open: boolean;
    machine?: MachineDto;
  }

  const [editMachineDialog, setEditMachineDialog] = React.useState({
    open: false,
  } as EditMachineDialogData);

  const [reloadData, setReloadData] = useState(0);

  // Relaod
  const reload = () => {
    setReloadData(reloadData + 1);
  };

  /** fetch data */
  useEffect(() => {
    const loadMachineData = async () => {
      const api = await getMachinesApi();
      try {
        const result = await api.getMachines();
        setMachinesData(result);
      } catch (error) {
        if (error instanceof ResponseError) {
          if (error.response && error.response.status === 401) {
            showError('User Unauthorized!');
          } else {
            showError(error.message);
          }
        } else {
          showError('There was an error fetching the data!');
        }
      }
    };
    trackPromise(loadMachineData(), 'machines');
  }, [reloadData, showError]);

  console.log('props.machinesData IN MACHINE PAGE:', machinesData);
  console.log('editMachineDialog in MACHINE PAGE:', editMachineDialog);

  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={12} style={{ flex: 1 }}>
          <Title>Machines</Title>
        </Grid>
        <Grid item className={classes.buttonRight}>
          <Grid container spacing={2} justify="flex-end" direction="row">
            <Grid item>
              <Button variant="outlined" color="primary" startIcon={<Cached />} onClick={reload}>
                Reload
              </Button>
            </Grid>
            {Config.machinesPage.canEditMachines && (
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon />}
                  onClick={() => setEditMachineDialog({ open: true, machine: undefined })}
                >
                  New Machine
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <MachinesTable
        machinesData={machinesData}
        promiseInProgress={promiseInProgress}
        setEditMachineDialog={setEditMachineDialog}
      />
      {Config.machinesPage.canEditMachines && (
        <MachineDialog
          open={editMachineDialog.open}
          machineDto={editMachineDialog.machine}
          onClose={() => setEditMachineDialog({ open: false })}
          onSave={() => {
            setEditMachineDialog({ open: false });
            reload();
          }}
        />
      )}
    </React.Fragment>
  );
}
