import { MachineActivityDto, PaymentDto, PaymentInformationDto } from '../../../generated';
import { Grid, Paper, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useStyles } from '../../Styles';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import { getPaymentsApi } from '../../../common/keycloak';
import { MachineActivityTable } from '../../assets/MachineActivityTable';

export function PaymentDetails(props: { payment: PaymentDto }): JSX.Element {
  const classes = useStyles();

  const { promiseInProgress } = usePromiseTracker({ area: 'payment-details', delay: 200 });
  const [machineActivities, setMachineActivities] = useState([] as MachineActivityDto[]);

  // Load machine activities
  useEffect(() => {
    const loadMachineActivities = async function () {
      const paymentsApi = await getPaymentsApi();
      const machineActivities = await paymentsApi.getMachineActivitiesForPayment(props.payment.paymentId);
      setMachineActivities(machineActivities);
    };

    trackPromise(loadMachineActivities(), 'payment-details');
  }, [props.payment.paymentId]);

  const PaymentInformation = function (props: {
    label: string;
    paymentInformation: PaymentInformationDto;
  }): JSX.Element {
    return (
      <>
        <Typography gutterBottom variant="subtitle2">
          {props.label}
        </Typography>
        <Grid container spacing={1}>
          <Grid item>
            <Grid item>
              <Typography variant="body2">Legal Name:</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2">IBAN:</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2">BIC:</Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Grid item>
              <Typography variant="body2">{props.paymentInformation.legalName}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2">{props.paymentInformation.iban}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2">{props.paymentInformation.bic}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  };

  const IDInformation = function (props: { machineId: string; paymentId: string }) {
    return (
      <>
        <Typography gutterBottom variant="subtitle2">
          IDs
        </Typography>
        <Grid container spacing={1}>
          <Grid item>
            <Grid item>
              <Typography variant="body2">Payment ID:</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2">Machine ID:</Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Grid item>
              <Typography variant="body2">{props.paymentId}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2">{props.machineId}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    <div className={classes.divTable}>
      <Grid item container direction="column" spacing={1}>
        <Grid item>
          <Paper className={classes.papertable}>
            <Grid container direction="row" spacing={4}>
              <Grid item lg={4} md={6} xs={12}>
                <IDInformation machineId={props.payment.machineId} paymentId={props.payment.paymentId} />
              </Grid>
              <Grid item lg={4} md={6} xs={12}>
                <PaymentInformation label="Payer" paymentInformation={props.payment.payerPaymentInformation} />
              </Grid>
              <Grid item lg={4} md={6} xs={12}>
                <PaymentInformation label="Payee" paymentInformation={props.payment.payeePaymentInformation} />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item>
          <Paper className={classes.papertable}>
            <Typography gutterBottom variant="subtitle1">
              Included Machine Activities
            </Typography>
            <MachineActivityTable machineActivities={machineActivities} promiseInProgress={promiseInProgress} />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
