import { GetMachineActivitiesActivityStatusEnum, MachineActivityDto, MachineDto } from '../../../generated';
import { Grid, List, ListItem, ListItemText, Paper, Typography } from '@material-ui/core';
import { formatCordaX500Name, formatDateTime } from '../../../common/format';
import React, { useEffect, useState } from 'react';
import { useStyles } from '../../Styles';
import { getMachinesApi } from '../../../common/keycloak';
import { format } from 'date-fns';
import useNotifications from '../../assets/useNotifications';
import Button from '@material-ui/core/Button';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import { MachineActivityTable } from '../../assets/MachineActivityTable';
import GetAppIcon from '@material-ui/icons/GetApp';
import { DetailsBlock } from '../../assets/details/DetailsBlock';
import { DetailsEntry } from '../../assets/details/DetailsEntry';

export function MachineDetails(props: { machine: MachineDto }): JSX.Element {
  const classes = useStyles();

  const { showInfo } = useNotifications();
  const { promiseInProgress } = usePromiseTracker({ area: 'machine-details', delay: 200 });
  const [machineActivities, setMachineActivities] = useState([] as MachineActivityDto[]);

  // Load machine activities
  useEffect(() => {
    const loadMachineActivities = async function () {
      const machinesApi = await getMachinesApi();
      const machineActivities = await machinesApi.getMachineActivities(
        props.machine.machineId,
        GetMachineActivitiesActivityStatusEnum.Unpaid
      );
      setMachineActivities(machineActivities);
    };

    trackPromise(loadMachineActivities(), 'machine-details');
  }, [props.machine.machineId]);

  /**
   * Function to download machine activities CSV
   * @param machineId
   * @param filePrefix
   */
  async function downloadMachineActivity(machineId: string, filePrefix: string) {
    const api = await getMachinesApi();
    try {
      // Get csvData
      const csvData = await api.getMachineActivitiesCsv(machineId);

      // Create blob and add create object URL via File API
      const blob = new Blob([csvData], { type: 'text/csv' });
      const objectUrl = window.URL.createObjectURL(blob);

      // Create anchor element to initialize download
      const anchor = document.createElement('a');
      anchor.href = objectUrl;
      anchor.download = filePrefix + '_' + format(new Date(), 'yyyyMMdd_HHmmss') + '.csv';
      anchor.click();

      // Free object url
      window.URL.revokeObjectURL(objectUrl);

      showInfo('Download successful');
    } catch (error) {
      showInfo('Failed to download activities');
    }
  }

  return (
    <div className={classes.divTable}>
      <Grid item xs container direction="column" spacing={1}>
        <Grid item>
          <Paper className={classes.papertable}>
            <Grid container direction="row" spacing={4}>
              <Grid item md={6} xs={12}>
                <DetailsBlock title="Machine Details">
                  <DetailsEntry property="Machine ID">{props.machine.machineId}</DetailsEntry>
                  <DetailsEntry property="Machine Observers">
                    <List dense disablePadding>
                      {props.machine.machineObservers.map((observer, index) => {
                        return (
                          <ListItem dense disableGutters key={index + 10000}>
                            <ListItemText>{formatCordaX500Name(observer)}</ListItemText>
                          </ListItem>
                        );
                      })}
                    </List>
                  </DetailsEntry>
                </DetailsBlock>
              </Grid>
              <Grid item md={6} xs={12}>
                <DetailsBlock title="Timestamps">
                  <DetailsEntry property="Created">{formatDateTime(props.machine.created)}</DetailsEntry>
                  <DetailsEntry property="Updated">{formatDateTime(props.machine.updated)}</DetailsEntry>
                </DetailsBlock>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid item>
          <Paper className={classes.papertable}>
            <Grid container direction="row" spacing={2}>
              <Grid item>
                <Typography gutterBottom variant="subtitle1">
                  Machine Activities
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  startIcon={<GetAppIcon />}
                  onClick={() => downloadMachineActivity(props.machine.machineId, props.machine.machineName)}
                >
                  CSV
                </Button>
              </Grid>
            </Grid>
            <MachineActivityTable machineActivities={machineActivities} promiseInProgress={promiseInProgress} />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
