import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import { useFormik } from 'formik';
import * as yup from 'yup';
import FormikForm from '../../../assets/formik/FormikForm';
import { Grid, LinearProgress } from '@material-ui/core';
import { InsuranceDetailsDto, InsuranceDto, InsuranceUpdateRequest } from '../../../../generated';
import FormikTextField from '../../../assets/formik/FormikTextField';
import useInsuranceApi from '../useInsuranceApi';
import { NumberFormatEurInput } from '../../../assets/numberformat/NumberFormatEur';
import DialogContentText from '@material-ui/core/DialogContentText';
import FormikKeyboardDateTimePicker from '../../../assets/formik/FormikKeyboardDateTimePicker';

/**
 * JSX Element that is an overlay dialog to edit an insurance
 * @param props
 */
export default function InsuranceDetailsDialog(props: {
  open: boolean;
  onClose: () => void;
  onSave: () => void;
  insuranceDto: InsuranceDto;
}): JSX.Element {
  const { promiseInProgress } = usePromiseTracker({ area: 'insurance-dialog', delay: 200 });

  const { handleUpdate } = useInsuranceApi();

  /**
   * Interface to store the current state of the form used by formik
   */
  interface InsuranceDetailsDialogType {
    type: string;
    startTimestamp?: Date;
    endTimestamp?: Date;
    insuredAmount?: number;
    excessAmount?: number;
    insuredRisks: string;
  }

  const mapToInsuranceUpdateRequest = function (
    insuranceDetailsDialogType: InsuranceDetailsDialogType
  ): InsuranceUpdateRequest {
    return {
      insuranceStatus: props.insuranceDto.insuranceStatus,
      insuranceDetails: mapToInsuranceDetailsDto(insuranceDetailsDialogType),
      machineDetails: props.insuranceDto.machineDetails,
      paymentDetails: props.insuranceDto.paymentDetails,
      payPerUseDetails: props.insuranceDto.payPerUseDetails,
    };
  };

  const mapToInsuranceDetailsDto = function (
    insuranceDetailsDialogType: InsuranceDetailsDialogType
  ): InsuranceDetailsDto {
    return {
      type: insuranceDetailsDialogType.type || '',
      // Change 'null' to 'undefined' as autogenerated api can't handle 'null' for date
      startTimestamp: insuranceDetailsDialogType.startTimestamp || undefined,
      endTimestamp: insuranceDetailsDialogType.endTimestamp || undefined,
      insuredAmount: insuranceDetailsDialogType.insuredAmount,
      excessAmount: insuranceDetailsDialogType.excessAmount,
      insuredRisks: insuranceDetailsDialogType.insuredRisks.split(';'),
    };
  };

  const mapToInsuranceDetailsDialogType = function (insuranceDto: InsuranceDetailsDto): InsuranceDetailsDialogType {
    return {
      type: insuranceDto.type || '',
      startTimestamp: insuranceDto.startTimestamp,
      endTimestamp: insuranceDto.endTimestamp,
      insuredAmount: insuranceDto.insuredAmount,
      excessAmount: insuranceDto.excessAmount,
      insuredRisks: insuranceDto.insuredRisks?.join(';') || '',
    };
  };

  const validationSchema = yup.object({
    type: yup.string().max(128, 'Field may not exceed 128 characters'),
    insuredAmount: yup.number().min(0.0, "Field can't be negative"),
    excessAmount: yup.number().min(0.0, "Field can't be negative"),
  });

  const formik = useFormik({
    initialValues: {} as InsuranceDetailsDialogType,
    validationSchema: validationSchema,
    onSubmit: (values) =>
      handleUpdate(props.insuranceDto.insuranceExternalId, mapToInsuranceUpdateRequest(values), props.onSave),
  });

  // Initialize form
  useEffect(() => {
    if (!props.open) return;
    formik.resetForm();

    const initialize = async function () {
      if (props.insuranceDto) {
        await formik.setValues(mapToInsuranceDetailsDialogType(props.insuranceDto.insuranceDetails));
        await formik.validateForm();
      }
    };

    trackPromise(initialize(), 'insurance-dialog');

    // Adding 'formik' to dependencies creates an infinite loop as formik changes every render
  }, [props.open, props.insuranceDto]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Dialog open={props.open} onClose={props.onClose} aria-labelledby="form-dialog-title">
      {/* Without div the progress bar is not visible when scrollbar is shown */}
      {promiseInProgress && (
        <div>
          <LinearProgress />
        </div>
      )}
      <DialogTitle id="form-dialog-title">Edit insurance details</DialogTitle>
      <FormikForm formik={formik}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormikTextField
                formik={formik}
                id="type"
                variant="outlined"
                fullWidth
                label="Type"
                disabled={promiseInProgress}
              />
            </Grid>
            <Grid item xs={12}>
              <FormikKeyboardDateTimePicker
                formik={formik}
                id="startTimestamp"
                variant="inline"
                inputVariant="outlined"
                fullWidth
                label="Start timestamp"
                disabled={promiseInProgress}
              />
            </Grid>
            <Grid item xs={12}>
              <FormikKeyboardDateTimePicker
                formik={formik}
                id="endTimestamp"
                variant="inline"
                inputVariant="outlined"
                fullWidth
                label="End timestamp"
                disabled={promiseInProgress}
              />
            </Grid>
            <Grid item xs={12}>
              <FormikTextField
                formik={formik}
                id="insuredAmount"
                variant="outlined"
                fullWidth
                label="Insured amount"
                InputProps={{
                  inputComponent: NumberFormatEurInput as never,
                }}
                disabled={promiseInProgress}
              />
            </Grid>
            <Grid item xs={12}>
              <FormikTextField
                formik={formik}
                id="excessAmount"
                variant="outlined"
                fullWidth
                label="Excess amount"
                InputProps={{
                  inputComponent: NumberFormatEurInput as never,
                }}
                disabled={promiseInProgress}
              />
            </Grid>
            <Grid item xs={12}>
              <DialogContentText>Separate insured risk items by semicolon &quot;;&quot;</DialogContentText>
              <FormikTextField
                formik={formik}
                id="insuredRisks"
                variant="outlined"
                fullWidth
                multiline
                label="Insured risks"
                disabled={promiseInProgress}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={props.onClose}>
            Close
          </Button>
          <Button variant="contained" color="primary" type="submit" disabled={promiseInProgress || !formik.isValid}>
            Save
          </Button>
        </DialogActions>
      </FormikForm>
    </Dialog>
  );
}
