import { MembershipStatusEnum } from '../../../generated';
import ColorChip from '../../assets/ColorChip';
import React from 'react';
import { ChipProps } from '@material-ui/core';

type MemberStatusChipProps = ChipProps & { membershipStatus?: MembershipStatusEnum };

/**
 * Show the membership status as chip
 * @param props the status
 */
export default function MemberStatusChip(props: MemberStatusChipProps): JSX.Element {
  const { membershipStatus, ...other } = props;

  let chipColor: 'grey' | 'green' | 'orange' = 'green';
  let label = '';
  switch (membershipStatus) {
    case MembershipStatusEnum.Active: {
      chipColor = 'green';
      label = 'Active';
      break;
    }
    case MembershipStatusEnum.Pending: {
      chipColor = 'orange';
      label = 'Pending';
      break;
    }
    case MembershipStatusEnum.Suspended: {
      chipColor = 'grey';
      label = 'Suspended';
      break;
    }
  }

  return <ColorChip chipColor={chipColor} label={label} {...other} />;
}
