import React from 'react';
import { CircularProgress, IconButton, Paper } from '@material-ui/core';
import { MaterialTableIcons } from '../../../assets/MaterialTableIcons';
import MaterialTable, { Column } from 'material-table';
import { InsuranceInvoiceDto } from '../../../../generated';
import { dateComparer, formatDateTime } from '../../../../common/format';
import { Edit } from '@material-ui/icons';
import { Config } from '../../../../common/config';
import InsuranceInvoiceStatusChip from './InsuranceInvoiceStatusChip';
import { NumberFormatEurText } from '../../../assets/numberformat/NumberFormatEur';
import { InsuranceInvoiceDetails } from './InsuranceInvoiceDetails';

export function InsuranceInvoicesTable(props: {
  insurancesData: InsuranceInvoiceDto[];
  promiseInProgress: boolean;
  setEditInsuranceInvoiceDialog: (open: { open: boolean; insuranceInvoice: InsuranceInvoiceDto }) => void;
}): JSX.Element {
  const ColumnPeriodStart: Column<InsuranceInvoiceDto> = {
    title: 'Period Start',
    field: 'invoiceDetails.periodStart',
    type: 'date',
    render: (data: InsuranceInvoiceDto) => formatDateTime(data.invoiceDetails.periodStart),
    customFilterAndSearch: (term: string, data: InsuranceInvoiceDto) =>
      dateComparer(term, data.invoiceDetails.periodStart),
    filterPlaceholder: 'select date',
  };

  const ColumnPeriodEnd: Column<InsuranceInvoiceDto> = {
    title: 'Period End',
    field: 'invoiceDetails.periodEnd',
    type: 'date',
    render: (data: InsuranceInvoiceDto) => formatDateTime(data.invoiceDetails.periodEnd),
    customFilterAndSearch: (term: string, data: InsuranceInvoiceDto) =>
      dateComparer(term, data.invoiceDetails.periodEnd),
    filterPlaceholder: 'select date',
  };

  const ColumnBaseAmount: Column<InsuranceInvoiceDto> = {
    title: 'Base Amount',
    field: 'invoiceDetails.baseAmount',
    headerStyle: { textAlign: 'right' },
    cellStyle: { textAlign: 'right' },
    render: (data: InsuranceInvoiceDto) => <NumberFormatEurText value={data.invoiceDetails.baseAmount} />,
    customFilterAndSearch: (term: number, rowData: InsuranceInvoiceDto) => {
      return rowData.invoiceDetails.baseAmount ? term <= rowData.invoiceDetails.baseAmount : false;
    },
    filterPlaceholder: 'larger than',
  };

  const ColumnPayPerUseAmount: Column<InsuranceInvoiceDto> = {
    title: 'Pay per Use Amount',
    field: 'invoiceDetails.payPerUseAmount',
    headerStyle: { textAlign: 'right' },
    cellStyle: { textAlign: 'right' },
    render: (data: InsuranceInvoiceDto) => <NumberFormatEurText value={data.invoiceDetails.payPerUseAmount} />,
    customFilterAndSearch: (term: number, rowData: InsuranceInvoiceDto) => {
      return rowData.invoiceDetails.payPerUseAmount ? term <= rowData.invoiceDetails.payPerUseAmount : false;
    },
    filterPlaceholder: 'larger than',
  };

  const ColumnInvoiceAmount: Column<InsuranceInvoiceDto> = {
    title: 'Invoice Amount',
    field: 'invoiceDetails.invoiceAmount',
    headerStyle: { textAlign: 'right' },
    cellStyle: { textAlign: 'right' },
    render: (data: InsuranceInvoiceDto) => <NumberFormatEurText value={data.invoiceDetails.invoiceAmount} />,
    customFilterAndSearch: (term: number, rowData: InsuranceInvoiceDto) => {
      return rowData.invoiceDetails.invoiceAmount ? term <= rowData.invoiceDetails.invoiceAmount : false;
    },
    filterPlaceholder: 'larger than',
  };

  const ColumnStatus: Column<InsuranceInvoiceDto> = {
    title: 'Status',
    field: 'insuranceStatus',
    filterPlaceholder: 'contains',
    headerStyle: { textAlign: 'center' },
    cellStyle: { textAlign: 'center' },
    render: (data: InsuranceInvoiceDto) => (
      <InsuranceInvoiceStatusChip insuranceInvoiceStatus={data.invoiceStatus} size="small" />
    ),
  };

  const ColumnEdit: Column<InsuranceInvoiceDto> = {
    title: 'Edit',
    sorting: false,
    headerStyle: { textAlign: 'center' },
    cellStyle: { textAlign: 'center' },
    render: (data: InsuranceInvoiceDto) => (
      <IconButton
        onClick={() => {
          props.setEditInsuranceInvoiceDialog({ open: true, insuranceInvoice: data });
        }}
      >
        <Edit />
      </IconButton>
    ),
  };

  const columnDefs: Column<InsuranceInvoiceDto>[] = [
    ColumnPeriodStart,
    ColumnPeriodEnd,
    ColumnInvoiceAmount,
    ColumnBaseAmount,
    ColumnPayPerUseAmount,
    ColumnStatus,
  ];

  // Add edit button
  if (Config.insurancePage.canEditInsurances) {
    columnDefs.push(ColumnEdit);
  }

  return (
    <MaterialTable
      localization={{
        body: {
          emptyDataSourceMessage: props.promiseInProgress ? <CircularProgress /> : 'No records to display',
        },
      }}
      components={{
        Container: (props) => <Paper {...props} elevation={0} />,
      }}
      icons={MaterialTableIcons()}
      columns={columnDefs}
      data={props.insurancesData}
      detailPanel={(row) => {
        return <InsuranceInvoiceDetails insuranceInvoice={row} />;
      }}
      options={{
        filtering: true,
        sorting: true,
        showTitle: false,
        search: false,
      }}
    />
  );
}
