import React from 'react';
import { ListItemIcon, ListItemText, Menu, MenuItem } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';
import { Config } from '../../common/config';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import keycloak from '../../common/keycloak';
import { KeycloakTokenParsed } from 'keycloak-js';
import Divider from '@material-ui/core/Divider';

type ParsedToken = KeycloakTokenParsed & {
  email?: string;
  preferred_username?: string;
  given_name?: string;
  family_name?: string;
  name?: string;
};

export default function UserMenu(props: { anchorEl: HTMLElement | null; onClose: () => void }): JSX.Element {
  const parsedToken: ParsedToken | undefined = keycloak?.tokenParsed;

  return (
    <Menu
      id="simple-menu"
      anchorEl={props.anchorEl}
      keepMounted
      open={Boolean(props.anchorEl)}
      onClose={props.onClose}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <MenuItem disabled={true}>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText>Profile: {Config.dashboard.profile}</ListItemText>
      </MenuItem>
      <MenuItem disabled={true}>
        <ListItemIcon>
          <AccountBoxIcon />
        </ListItemIcon>
        <ListItemText>User: {parsedToken?.preferred_username}</ListItemText>
      </MenuItem>
      <Divider />
      <MenuItem onClick={() => keycloak.logout()}>
        <ListItemIcon>
          <ExitToAppIcon />
        </ListItemIcon>
        <ListItemText primary="Logout" />
      </MenuItem>
    </Menu>
  );
}
