import React from 'react';
import { Title } from '../assets/Title';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';

/**
 * Definition of the about view component.
 */
export function ErrorPage(): JSX.Element {
  return (
    <React.Fragment>
      <Title>Error</Title>
      <br />
      <Typography variant="body1">
        The page you are looking for could not be found.
        <br />
        <br />
        Click <Link to="/">here</Link> to access the start page.
      </Typography>
    </React.Fragment>
  );
}
