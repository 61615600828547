import * as React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const PaymentInfoDeleteEditDialog = (props: {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  isDeleteable?: boolean;
  setIsDeleteable?: (value: boolean) => void;
  setIsEditable: (value: boolean) => void;
  isEditing?: boolean;
}): JSX.Element => {
  const confirmHandler = () => {
    props.onClose();
    props.onConfirm();
  };

  const cancelHandler = () => {
    if (props.setIsDeleteable) {
      props.setIsDeleteable(false);
    }
    if (props.isEditing) {
      props.setIsEditable(false);
    }
    props.onClose();
  };

  return (
    <React.Fragment>
      <Dialog
        open={props.open}
        onClose={props.onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Warning</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {`The bank account you are attempting to ${
              props.isDeleteable ? 'delete' : 'edit'
            } may be currently assigned as a payment method for a machine. Please ensure to assign a new account to the machine after ${
              props.isDeleteable ? 'deleting' : 'editing'
            }.`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelHandler}>Cancel</Button>
          <Button onClick={confirmHandler} autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default PaymentInfoDeleteEditDialog;
