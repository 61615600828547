import ColorChip from '../../../assets/ColorChip';
import React from 'react';
import { ChipProps } from '@material-ui/core';
import { InsuranceInvoiceStatusEnum } from '../../../../generated';

type InsuranceStatusChipProps = ChipProps & { insuranceInvoiceStatus?: InsuranceInvoiceStatusEnum };

/**
 * Show the insurance status as chip
 * @param props the status
 */
export default function InsuranceInvoiceStatusChip(props: InsuranceStatusChipProps): JSX.Element {
  const { insuranceInvoiceStatus, ...other } = props;

  let chipColor: 'grey' | 'green' | 'orange' = 'green';
  let label = '';
  switch (insuranceInvoiceStatus) {
    case InsuranceInvoiceStatusEnum.Paid: {
      chipColor = 'green';
      label = 'Paid';
      break;
    }
    case InsuranceInvoiceStatusEnum.Billed: {
      chipColor = 'orange';
      label = 'Billed';
      break;
    }
    case InsuranceInvoiceStatusEnum.Pending: {
      chipColor = 'orange';
      label = 'Pending';
      break;
    }
    case InsuranceInvoiceStatusEnum.Canceled: {
      chipColor = 'grey';
      label = 'Canceled';
      break;
    }
  }

  return <ColorChip chipColor={chipColor} label={label} {...other} />;
}
