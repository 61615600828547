import { MachineActivityDto, MachineActivityStatusEnum } from '../../generated';
import { CircularProgress, Paper } from '@material-ui/core';
import { MaterialTableIcons } from './MaterialTableIcons';
import MaterialTable, { Column } from 'material-table';
import React from 'react';
import { formatDateTime } from '../../common/format';
import { NumberFormatEurText } from './numberformat/NumberFormatEur';
import ColorChip from './ColorChip';
import { NumberFormatUnitsText } from './numberformat/NumberFormatUnits';

export function MachineActivityTable(props: {
  machineActivities: MachineActivityDto[];
  promiseInProgress: boolean;
}): JSX.Element {
  const ColumnActivityTimestamp: Column<MachineActivityDto> = {
    title: 'Activity Timestamp',
    field: 'activityTimestamp',
    type: 'datetime',
    render: (data: MachineActivityDto) => formatDateTime(data.activityTimestamp),
    defaultSort: 'desc',
  };

  const ColumnActivityUnits: Column<MachineActivityDto> = {
    title: 'Activity Units',
    field: 'activityUnits',
    headerStyle: { textAlign: 'right' },
    cellStyle: { textAlign: 'right' },
    render: (data: MachineActivityDto) => <NumberFormatUnitsText value={data.activityUnits} />,
  };

  const ColumnActivityPricePerUnit: Column<MachineActivityDto> = {
    title: 'Price per Unit',
    field: 'pricePerUnit',
    headerStyle: { textAlign: 'right' },
    cellStyle: { textAlign: 'right' },
    render: (data: MachineActivityDto) => <NumberFormatEurText value={data.pricePerUnit} />,
  };

  const ColumnActivityCost: Column<MachineActivityDto> = {
    title: 'Activity Cost',
    field: 'activityCost',
    headerStyle: { textAlign: 'right' },
    cellStyle: { textAlign: 'right' },
    render: (data: MachineActivityDto) => <NumberFormatEurText value={data.activityCost} />,
  };

  const ColumnActivityStatus: Column<MachineActivityDto> = {
    title: 'Activity Status',
    field: 'activityStatus',
    headerStyle: { textAlign: 'center' },
    cellStyle: { textAlign: 'center' },
    render: (data: MachineActivityDto) => {
      return <MachineActivityStatusChip activityStatus={data.activityStatus} />;
    },
  };

  const ColumnActivityNote: Column<MachineActivityDto> = {
    title: 'Activity Note',
    field: 'activityNote',
    filterPlaceholder: 'contains',
  };

  const detailColumnDefs: Column<MachineActivityDto>[] = [
    ColumnActivityTimestamp,
    ColumnActivityUnits,
    ColumnActivityPricePerUnit,
    ColumnActivityCost,
    ColumnActivityStatus,
    ColumnActivityNote,
  ];

  function MachineActivityStatusChip(props: { activityStatus: MachineActivityStatusEnum }) {
    let chipColor: 'green' | 'orange' = 'green';
    switch (props.activityStatus) {
      case MachineActivityStatusEnum.Paid: {
        chipColor = 'green';
        break;
      }
      case MachineActivityStatusEnum.Unpaid: {
        chipColor = 'orange';
        break;
      }
    }

    return <ColorChip chipColor={chipColor} size="small" label={props.activityStatus} />;
  }

  return (
    <MaterialTable
      localization={{
        body: {
          emptyDataSourceMessage: props.promiseInProgress ? <CircularProgress /> : 'No records to display',
        },
      }}
      components={{
        Container: (props) => <Paper {...props} elevation={0} />,
        Toolbar: () => <div />,
      }}
      icons={MaterialTableIcons()}
      columns={detailColumnDefs}
      data={props.machineActivities}
      options={{
        filtering: false,
        sorting: true,
        showTitle: false,
        paging: false,
        search: false,
      }}
    />
  );
}
